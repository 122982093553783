<template>
  <v-container class="notifs">
    <h2 class="notifs__title">{{ event.name }} - {{ $t('admin.notifications.title') }}</h2>

    <v-form
      class="notifs__content"
      v-model="valid"
      :disabled="!canCreate"
      @submit.prevent="sendNotification"
      data-test-id="form"
    >
      <v-row>
        <v-col cols="12">
          <vue-editor
            v-validate="'required'"
            data-vv-name="message"
            v-model="notificationForm.message"
            :editorToolbar="customToolbar"
            placeholder="-"
          ></vue-editor>
          <span v-if="hasMessageError">{{ getMessageError }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select
            v-model="selectedTickets"
            :items="tickets"
            multiple
            chips
            clearable
            item-text="name"
            :label="$t('admin.notifications.tickets')"
            :rules="rules.ticket"
            return-object
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon>
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> {{ $t('globals.selectAll') }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-for="(button, index) in notificationForm.buttons" :key="index" class="my-0 py-0">
        <v-col class="my-0 py-0" cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="notificationForm.buttons[index].href"
                :label="$t('admin.notifications.href')"
                :rules="rules.href"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="notificationForm.buttons[index].text"
                :label="$t('admin.notifications.buttonText')"
                :rules="rules.buttonText"
              />
            </v-col>

            <v-col class="d-flex align-center justify-center" cols="1" sm="1">
              <v-icon
                class="clickable"
                :data-test-id="`delete-button${index}`"
                @click="notificationForm.buttons.splice(index, 1)"
                >mdi-delete</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="add-button clickable"
        data-test-id="add-button"
        @click="notificationForm.buttons.push({ href: '', text: '' })"
      >
        {{ $t('admin.notifications.hasButton') }}
        <v-icon class="mb-1" color="primary" size="15px">mdi-plus</v-icon>
      </div>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="primary"
            :disabled="!valid || hasMessageError"
            type="submit"
            data-test-id="btn-submit"
          >
            {{ $t('admin.notifications.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';

import { Notification } from '@/models/notification/notification.model';
import { MessageType } from '@/models';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_TICKET_MODULE,
  GET_ADMIN_TICKETS,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import { SEND_NOTIFICATION, getNotificationSocket } from '@/socket/notification-namespace';

export default {
  name: 'NotificationsAdmin',
  components: {
    VueEditor,
  },
  data: () => ({
    notificationForm: new Notification(),
    selectedTickets: [],
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
    valid: false,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    hasMessageError() {
      return !!this.errors?.first('message');
    },
    getMessageError() {
      return this.$t('admin.notifications.errors.required.message');
    },
    rules() {
      return {
        ticket: [(v) => !!(v && v.length) || this.$t('admin.notifications.errors.required.ticket')],
        href: [
          VALIDATORS.REQUIRED.default(this.$t('admin.notifications.errors.required.href')),
          VALIDATORS.URL.FORMAT,
          VALIDATORS.LENGTH.MAX(256),
        ],
        buttonText: [
          VALIDATORS.REQUIRED.default(this.$t('admin.notifications.errors.required.buttonText')),
          VALIDATORS.LENGTH.MIN(2),
          VALIDATORS.LENGTH.MAX(75),
        ],
      };
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.notifications.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    selectAllTickets() {
      return this.selectedTickets.length === this.tickets.length;
    },
    selectSomeTickets() {
      return this.selectedTickets.length > 0 && !this.selectAllTickets;
    },
    icon() {
      if (this.selectAllTickets) return 'mdi-close-box';
      if (this.selectSomeTickets) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    ...mapActions(ADMIN_TICKET_MODULE, [GET_ADMIN_TICKETS]),
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    sendNotification() {
      this.notificationForm.tickets = this.selectedTickets;

      const notification = this.notificationForm;

      getNotificationSocket().emit(SEND_NOTIFICATION, notification);

      this[DISPLAY_MESSAGE]({
        text: this.$t(`admin.notifications.success`),
        type: MessageType.info,
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllTickets) {
          this.selectedTickets = [];
        } else {
          this.selectedTickets = this.tickets.slice();
        }
      });
    },
    handleButtonChange(hasButton) {
      this.notificationForm.button = hasButton ? { text: '', href: '' } : null;
    },
  },
  mounted() {
    const { eventId } = this.$route.params;
    this.notificationForm.eventId = eventId;
    this[GET_ADMIN_TICKETS](eventId);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

.notifs {
  padding: 25px 50px;
  min-height: calc(100vh - 6rem);

  &__title {
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}
</style>
